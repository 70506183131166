<template>
    <a-card class="greyCon" :style="{ backgroundColor: bg }">
        <a-statistic :value="CurrentData" :valueStyle="{fontWeight: 'bold'}"> 
            <template #prefix>{{ prefix }}</template>
            <template #suffix>{{ suffix }}</template>
            <template #title>
                <div class="flex-between">
                    <a-tooltip placement="right">
                        <span>{{ title }}</span>
                        <template #title>
                            <span>{{ tips }}</span>
                        </template>
                        <question-circle-outlined style="margin-left: 5px" />
                    </a-tooltip>
                    <chainIndex :CurrentData="CurrentData" :LastData="LastData"></chainIndex>
                </div>
            </template>
        </a-statistic>

    </a-card>
</template>

<script>
import chainIndex from "@/components/home/chainIndex";
export default {
    name: 'SaasCrmCard',
    props: ['CurrentData', 'LastData', 'title', 'tips', 'prefix', 'suffix', 'bg'],
    components: {
        chainIndex
    },
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style scoped>
.greyCon {
    /* background-color: rgb(248, 248, 248); */
}

.flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

:deep(.ant-statistic-content-prefix) {
    font-size: 14px;
}

:deep(.ant-statistic-content-suffix) {
    font-size: 14px;
}

:deep(.ant-card-body) {
    padding: 20px 12px;
}
</style>